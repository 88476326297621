import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

const ImageGallery = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const imageGrid = () => {
  return (
    <ImageGallery>
      <StaticImage
        src="../images/QP2/qp2-1.jpg"
        alt="Quinta dos Pastores II - Exterior 1"
      />
      <StaticImage
        src="../images/QP2/qp2-2.jpg"
        alt="Quinta dos Pastores II - Ping Pong"
      />
      <StaticImage
        src="../images/QP2/qp2-3.jpg"
        alt="Quinta dos Pastores II - Exterior 2"
      />
      <StaticImage
        src="../images/QP2/qp2-4.jpg"
        alt="Quinta dos Pastores II - Cozinha"
      />
      <StaticImage
        src="../images/QP2/qp2-5.jpg"
        alt="Quinta dos Pastores II - Quarto"
      />
      <StaticImage
        src="../images/QP2/qp2-6.jpg"
        alt="Quinta dos Pastores II - Sala"
      />
    </ImageGallery>
  );
};

export default imageGrid;
