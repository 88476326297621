import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

import icon1 from '../images/hero-icons/qp1/1.svg';
import icon2 from '../images/hero-icons/qp1/2.svg';
import icon31 from '../images/hero-icons/qp1/5.svg';
import icon3 from '../images/hero-icons/qp1/3.svg';
import icon4 from '../images/hero-icons/qp1/4.svg';
import icon6 from '../images/hero-icons/qp1/6.svg';
import icon7 from '../images/hero-icons/qp1/7.svg';
import icon9 from '../images/hero-icons/qp1/9.svg';
import icon10 from '../images/hero-icons/qp2/10.svg';
import icon12 from '../images/hero-icons/qp2/11.svg';
import icon13 from '../images/hero-icons/qp1/13.svg';

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  background-size: contain;
  z-index: 1;
  background-position: bottom right;
  background-repeat: no-repeat;
`;
const IntroSection = styled.section`
  background: #fff5e8;
  position: relative;
  .text-padding {
    padding: 5.875rem 2rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #722e00;
  }
`;

const IconList = styled.div`
  color: #722e00;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 3rem;
  text-align: center;
  span {
    display: inline-block;
    margin-right: 1rem;
  }
  img {
    height: 20px;
    margin-right: 0.5rem;
  }
`;

const DesktopVersion = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;

const MobileVersion = styled.div`
  p {
    text-align: center;
    max-width: 70ch;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;

const Intro = () => {
  const data = useStaticQuery(
    graphql`
      query {
        qp: file(relativePath: { eq: "intro-qp2.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp = data.qp.childImageSharp.fluid;
  return (
    <IntroSection>
      <DesktopVersion>
        <StyledBackgroundSection
          Tag="div"
          fluid={qp}
          backgroundColor={`#fff5e8`}
        >
          <div className="container">
            <div className="grid">
              <div className="col-md-6">
                <div className="text-padding">
                  <p>
                    Uma{' '}
                    <strong>
                      quinta privada com cerca de 2000m<sup>2</sup>
                    </strong>
                    , com uma{' '}
                    <strong>
                      vivenda de 170m<sup>2</sup> e piscina
                    </strong>
                    .
                  </p>
                  <p>
                    A vivenda dispõe de uma{' '}
                    <strong>
                      sala espaçosa, cozinha, 4 quartos e 4 casas de banho
                    </strong>
                    . Existe também um{' '}
                    <strong>segundo piso, com um espaço amplo</strong>, com 2/3
                    camas de casal de uma casa de banho. O{' '}
                    <strong>alpendre, junto à piscina</strong>, oferece imenso
                    espaço para refeições e convívios. A quinta dispõe ainda de
                    um <strong>barbecue para cozinhar um churrasco</strong>.
                  </p>
                  <p>
                    O confortável espaço exterior, junto à piscina, dispõe de
                    uma{' '}
                    <strong>
                      mesa de ping pong, um trampolim para as crianças e um
                      jardim
                    </strong>
                    . A quinta tem também outras{' '}
                    <strong>opções de entretenimentos</strong>, tais como:{' '}
                    <strong>bicicletas, jogo do Jenga, petanca, etc.</strong>
                  </p>
                  <p>
                    Por último, a{' '}
                    <strong>quinta é totalmente vedada e pet-friendly</strong> —
                    os vossos animais serão bem vindos.
                  </p>
                  <IconList>
                    <span>
                      <img src={icon1} alt="170m2" /> 170 m
                      <sup>
                        <small>2</small>
                      </sup>
                    </span>
                    <span>
                      <img src={icon2} alt="Quartos" /> 5 Quartos
                    </span>
                    <span>
                      <img src={icon31} alt="Pessoas" /> Até 12 Pessoas
                    </span>
                    <span>
                      <img src={icon3} alt="Piscina" /> Piscina
                    </span>
                    <span>
                      <img src={icon4} alt="Churrasco" /> Churrasco
                    </span>
                    <span>
                      <img src={icon6} alt="Bicicletas" /> Bicicletas
                    </span>
                    <span>
                      <img src={icon7} alt="Jogos" /> Jogos
                    </span>
                    <span>
                      <img src={icon9} alt="Trampolim" />
                      Trampolim
                    </span>
                    <span>
                      <img src={icon10} alt="Ping Pong" />
                      Ping Pong
                    </span>
                    <span>
                      <img src={icon12} alt="Animais" /> Animais
                    </span>
                    <span>
                      <img src={icon13} alt="Lareira" /> Ar Condicionado /
                      Lareira
                    </span>
                  </IconList>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </StyledBackgroundSection>
      </DesktopVersion>
      <MobileVersion>
        <div className="container">
          <div className="text-padding">
            <p>
              Uma{' '}
              <strong>
                quinta privada com cerca de 2000m<sup>2</sup>
              </strong>
              , com uma{' '}
              <strong>
                vivenda de 170m<sup>2</sup> e piscina
              </strong>
              .
            </p>
            <p>
              A vivenda dispõe de uma{' '}
              <strong>
                sala espaçosa, cozinha, 4 quartos e 4 casas de banho
              </strong>
              . Existe também um{' '}
              <strong>segundo piso, com um espaço amplo</strong>, com 2/3 camas
              de casal de uma casa de banho. O{' '}
              <strong>alpendre, junto à piscina</strong>, oferece imenso espaço
              para refeições e convívios. A quinta dispõe ainda de um{' '}
              <strong>barbecue para cozinhar um churrasco</strong>.
            </p>
            <p>
              O confortável espaço exterior, junto à piscina, dispõe de uma{' '}
              <strong>
                mesa de ping pong, um trampolim para as crianças e um jardim
              </strong>
              . A quinta tem também outras{' '}
              <strong>opções de entretenimentos</strong>, tais como:{' '}
              <strong>bicicletas, jogo do Jenga, petanca, etc.</strong>
            </p>
            <p>
              Por último, a{' '}
              <strong>quinta é totalmente vedada e pet-friendly</strong> — os
              vossos animais serão bem vindos.
            </p>
            <IconList>
              <span>
                <img src={icon1} alt="170m2" /> 170 m
                <sup>
                  <small>2</small>
                </sup>
              </span>
              <span>
                <img src={icon2} alt="Quartos" /> 5 Quartos
              </span>
              <span>
                <img src={icon31} alt="Pessoas" /> Até 12 Pessoas
              </span>
              <span>
                <img src={icon3} alt="Piscina" /> Piscina
              </span>
              <span>
                <img src={icon4} alt="Churrasco" /> Churrasco
              </span>
              <span>
                <img src={icon6} alt="Bicicletas" /> Bicicletas
              </span>
              <span>
                <img src={icon7} alt="Jogos" /> Jogos
              </span>
              <span>
                <img src={icon9} alt="Trampolim" />
                Trampolim
              </span>
              <span>
                <img src={icon10} alt="Ping Pong" />
                Ping Pong
              </span>
              <span>
                <img src={icon12} alt="Animais" /> Animais
              </span>
              <span>
                <img src={icon13} alt="Lareira" /> Ar Condicionado / Lareira
              </span>
            </IconList>
          </div>
        </div>
      </MobileVersion>
    </IntroSection>
  );
};

export default Intro;
