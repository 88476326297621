import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ToDoSection = styled.section`
  background: var(--beije);
  @media (max-width: 767px) {
    .mobile-first {
      grid-row: 1;
    }
  }
`;

const Heading = styled.div`
  padding: 5.875rem 0;
  h2 {
    color: var(--brown);
    font-family: 'Abril Fatface';
    text-transform: uppercase;
    font-size: 3.125rem;
  }
`;

const Grid1 = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  color: var(--light-green);
  background-color: var(--green);
  div {
    padding: 2rem;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    div {
      padding: 2rem;
    }
    div:first-of-type {
      min-height: 320px;
    }
  }
`;

const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  background-color: var(--light-brown);
  div {
    padding: 1rem;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    div {
      padding: 2rem;
    }
    div:nth-of-type(2) {
      min-height: 320px;
    }
  }
`;

const GridData = styled.div`
  color: var(--light-green);
  width: 100%;
  min-height: 25rem;
  @media (max-width: 767px) {
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  h3,
  p {
    margin-bottom: 1rem;
    max-width: 300px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 900;
    text-transform: uppercase;
  }
  p {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  div {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: end;
    @media (max-width: 767px) {
      padding: 0;
      text-align: left;
      justify-content: center;
      align-items: initial;
    }
  }
`;

const WhatToDoQP1 = () => {
  const data = useStaticQuery(
    graphql`
      query {
        qp: file(relativePath: { eq: "QP2/grid/qpgrid-1.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp2: file(relativePath: { eq: "QP2/grid/qpgrid-2.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp3: file(relativePath: { eq: "QP2/grid/qpgrid-3.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp4: file(relativePath: { eq: "QP2/grid/qpgrid-3.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp5: file(relativePath: { eq: "QP2/grid/qpgrid-5.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp6: file(relativePath: { eq: "QP2/grid/qpgrid-6.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp7: file(relativePath: { eq: "QP2/grid/qpgrid-7.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp = data.qp.childImageSharp.fluid;
  const qp2 = data.qp2.childImageSharp.fluid;
  const qp3 = data.qp3.childImageSharp.fluid;
  const qp4 = data.qp4.childImageSharp.fluid;
  const qp5 = data.qp5.childImageSharp.fluid;
  const qp6 = data.qp6.childImageSharp.fluid;
  return (
    <ToDoSection>
      <Heading>
        <div className="container">
          <h2 className="center">O Que Fazer na Quinta</h2>
        </div>
      </Heading>
      <Grid1>
        <StyledBackgroundSection
          Tag="div"
          fluid={qp}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
        <GridData>
          <h3>ALIMENTAR E CONVIVER COM OS NOSSOS ANIMAIS</h3>
          <p>
            Na QPII temos algumas galinhas. Os hóspedes podem alimentá-las e
            apanhar os ovos frescos para o pequeno almoço.
          </p>
          <p>
            São ovos biológicos e muito saudáveis, uma vez que as galinhas são
            criadas com muita tranquilidade.
          </p>
        </GridData>
      </Grid1>
      <Grid2>
        <GridData>
          <div>
            <h3>PISCINA</h3>
            <p>
              Nada melhor que uns excelentes banhos para refrescar o corpo nas
              tardes quentes de verão.
            </p>
          </div>
        </GridData>
        <StyledBackgroundSection
          Tag="div"
          className="mobile-first"
          fluid={qp2}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
      </Grid2>
      <Grid1>
        <StyledBackgroundSection
          Tag="div"
          fluid={qp3}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
        <GridData>
          <h3>PING PONG</h3>
          <p>
            O ténis de mesa é um dos desportos mais praticados em todo o mundo.
          </p>
          <p>
            As mesas de ping pong são uma ótima diversão para quem quer passar o
            seu tempo a praticar desporto de forma divertida e relaxante.
          </p>
        </GridData>
      </Grid1>
      <Grid2>
        <GridData>
          <div>
            <h3>TRAMPOLIM</h3>
            <p>
              Tudo a saltar, tudo a saltar!!! Os mais pequenos têm muita energia
              e precisam de brincar e divertir-se. Os trampolins oferecem muitas
              possibilidades, sendo um brinquedo que mantém os mais pequenos da
              casa ativos. Brincar ao ar livre é também muito importante e algo
              que adoram.
            </p>
          </div>
        </GridData>
        <StyledBackgroundSection
          Tag="div"
          className="mobile-first"
          fluid={qp4}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
      </Grid2>
      <Grid1>
        <StyledBackgroundSection
          Tag="div"
          fluid={qp5}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
        <GridData>
          <h3>BALOIÇO</h3>
          <p>
            Na nossa quinta, pode fazer uma sesta na cama-baloiço escutando os
            passarinhos.
          </p>
        </GridData>
      </Grid1>
      <Grid2>
        <GridData>
          <div>
            <h3>BICICLETAS</h3>
            <p>
              Deixamos ao vosso dispor 3 Bicicletas para que passeiem pela
              natureza do Ribatejo, para ir até ao mercado ou para fazerem
              exercício.
            </p>
          </div>
        </GridData>
        <StyledBackgroundSection
          Tag="div"
          className="mobile-first"
          fluid={qp6}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
      </Grid2>
    </ToDoSection>
  );
};

export default WhatToDoQP1;
