import React, { useEffect, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import MenuContext from '../context/MenuContext';
import CallBtn from '../components/callBtn';
import QpHeader from '../components/qp2Header';
import Intro from '../components/intro-qp2';
import ImageGrid from '../components/imageGrid-QP2';
import WhatToDo from '../components/whatTodo-QP2';
import Reviews from '../components/reviews-QP2';
import ContactForm from '../components/contactForm';

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        qp: file(relativePath: { eq: "contact-bg-qp2.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp = data.qp.childImageSharp.fluid;
  const menu = useContext(MenuContext);
  const title = 'Quinta dos Pastores II';
  const tagline =
    'A sua quinta privada com piscina, trampolim, bicicletas, ping pong e churrasco, para umas férias em família junto da natureza.';

  useEffect(() => {
    if (menu.show) {
      menu.toggleShow();
    }
  }, []);
  return (
    <Layout>
      <Seo title={title} description={tagline} />
      <CallBtn />
      <QpHeader title={title} tagline={tagline} color="#5f2701" />
      <Intro />
      <ImageGrid />
      <WhatToDo />
      <Reviews />
      <ContactForm background={qp} />
    </Layout>
  );
};

export default IndexPage;
